
export default {
  props: {
    links: {
      type: Array,
      default: () => [],
    },
    flex: {
      type: String,
      default: () => "",
    },
  },
  methods: {
    showCategories() {
      this.$emit("showMenu");
    },
  },
};
